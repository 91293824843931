import React, { Dispatch } from 'react';
import { FeaturedEntriesReducer } from './reducer';
import { State, Action } from './types';

export const INITIAL_STATE: State = {
  featuredEntries: '',
};

export const FeaturedEntriesContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export default function FeaturedEntriesProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = React.useReducer(FeaturedEntriesReducer, INITIAL_STATE);
  return <FeaturedEntriesContext.Provider value={{ state, dispatch }}>{children}</FeaturedEntriesContext.Provider>;
}
