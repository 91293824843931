import { State, Action, ACTIONS } from './types';

export const ModalReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.setModalState: {
      return {
        ...state,
        modalName: action.payload.modalName,
        props: action.payload.props,
      };
    }
    case ACTIONS.setPortalNode: {
      return {
        ...state,
        node: action.payload,
      };
    }
    case ACTIONS.clearModal: {
      return {
        ...state,
        modalName: undefined,
        props: undefined,
      };
    }
    default: {
      console.error(`Unhandled action type: ${(action as Action).type}`);
      return { ...state };
    }
  }
};
