import { ACTIONS, ModalNames, ModalTypes, OpenModalPayload } from './types';

export const setOpenedModal = (
  modalName: ModalNames,
  props: ModalTypes[ModalNames],
): { type: ACTIONS.setModalState; payload: OpenModalPayload } => ({
  type: ACTIONS.setModalState,
  payload: { modalName, props },
});

export const setPortalNode = (node: HTMLDivElement): { type: ACTIONS.setPortalNode; payload: HTMLDivElement } => ({
  type: ACTIONS.setPortalNode,
  payload: node,
});

export const clearModal = (): { type: ACTIONS.clearModal } => ({
  type: ACTIONS.clearModal,
});
