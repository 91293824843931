exports.components = {
  "component---src-components-templates-about-page-about-page-tsx": () => import("./../../../src/components/templates/AboutPage/AboutPage.tsx" /* webpackChunkName: "component---src-components-templates-about-page-about-page-tsx" */),
  "component---src-components-templates-blog-post-blog-post-tsx": () => import("./../../../src/components/templates/BlogPost/BlogPost.tsx" /* webpackChunkName: "component---src-components-templates-blog-post-blog-post-tsx" */),
  "component---src-components-templates-careers-page-careers-page-tsx": () => import("./../../../src/components/templates/CareersPage/CareersPage.tsx" /* webpackChunkName: "component---src-components-templates-careers-page-careers-page-tsx" */),
  "component---src-components-templates-contact-page-contact-page-tsx": () => import("./../../../src/components/templates/ContactPage/ContactPage.tsx" /* webpackChunkName: "component---src-components-templates-contact-page-contact-page-tsx" */),
  "component---src-components-templates-content-page-content-page-tsx": () => import("./../../../src/components/templates/ContentPage/ContentPage.tsx" /* webpackChunkName: "component---src-components-templates-content-page-content-page-tsx" */),
  "component---src-components-templates-customers-customers-tsx": () => import("./../../../src/components/templates/Customers/Customers.tsx" /* webpackChunkName: "component---src-components-templates-customers-customers-tsx" */),
  "component---src-components-templates-demo-page-demo-page-tsx": () => import("./../../../src/components/templates/DemoPage/DemoPage.tsx" /* webpackChunkName: "component---src-components-templates-demo-page-demo-page-tsx" */),
  "component---src-components-templates-global-resource-global-resource-tsx": () => import("./../../../src/components/templates/GlobalResource/GlobalResource.tsx" /* webpackChunkName: "component---src-components-templates-global-resource-global-resource-tsx" */),
  "component---src-components-templates-glossary-detail-page-glossary-detail-page-tsx": () => import("./../../../src/components/templates/GlossaryDetailPage/GlossaryDetailPage.tsx" /* webpackChunkName: "component---src-components-templates-glossary-detail-page-glossary-detail-page-tsx" */),
  "component---src-components-templates-glossary-page-glossary-page-tsx": () => import("./../../../src/components/templates/GlossaryPage/GlossaryPage.tsx" /* webpackChunkName: "component---src-components-templates-glossary-page-glossary-page-tsx" */),
  "component---src-components-templates-home-home-tsx": () => import("./../../../src/components/templates/Home/Home.tsx" /* webpackChunkName: "component---src-components-templates-home-home-tsx" */),
  "component---src-components-templates-main-main-tsx": () => import("./../../../src/components/templates/Main/Main.tsx" /* webpackChunkName: "component---src-components-templates-main-main-tsx" */),
  "component---src-components-templates-news-news-tsx": () => import("./../../../src/components/templates/News/News.tsx" /* webpackChunkName: "component---src-components-templates-news-news-tsx" */),
  "component---src-components-templates-platform-platform-tsx": () => import("./../../../src/components/templates/Platform/Platform.tsx" /* webpackChunkName: "component---src-components-templates-platform-platform-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-br-404-tsx": () => import("./../../../src/pages/br/404.tsx" /* webpackChunkName: "component---src-pages-br-404-tsx" */),
  "component---src-pages-de-404-tsx": () => import("./../../../src/pages/de/404.tsx" /* webpackChunkName: "component---src-pages-de-404-tsx" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-es-404-tsx": () => import("./../../../src/pages/es/404.tsx" /* webpackChunkName: "component---src-pages-es-404-tsx" */),
  "component---src-pages-fr-404-tsx": () => import("./../../../src/pages/fr/404.tsx" /* webpackChunkName: "component---src-pages-fr-404-tsx" */),
  "component---src-pages-ja-404-tsx": () => import("./../../../src/pages/ja/404.tsx" /* webpackChunkName: "component---src-pages-ja-404-tsx" */)
}

