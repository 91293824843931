export enum ACTIONS {
  setConfig = 'SET_CONFIG',
}

export type State = {
  config?: PageConfig;
};

export type Action = {
  type: ACTIONS.setConfig;
  payload: PageConfig;
};
