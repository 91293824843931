import React, { Dispatch } from 'react';
import { ConfigReducer } from './reducer';
import { State, Action } from './types';

export const INITIAL_STATE: State = {
  config: undefined,
};

export const ConfigContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export default function ConfigProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = React.useReducer(ConfigReducer, INITIAL_STATE);
  return <ConfigContext.Provider value={{ state, dispatch }}>{children}</ConfigContext.Provider>;
}
