import { State, Action, ACTIONS } from './types';

export const ConfigReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.setConfig: {
      return {
        config: action.payload,
      };
    }
    default: {
      console.error(`Unhandled action type: ${(action as Action).type}`);
      return { ...state };
    }
  }
};
