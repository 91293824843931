export enum ACTIONS {
  setModalState = 'SET_MODAL_STATE',
  setPortalNode = 'SET_PORTAL_NODE',
  clearModal = 'CLEAR_MODAL',
}

export type State = {
  node?: HTMLDivElement;
  modalName?: ModalNames;
  props?: ModalTypes[ModalNames];
};

export enum ModalNames {
  LEADERSHIP = 'leadership',
  VIDEO = 'video',
}

export type ModalTypes = {
  [ModalNames.LEADERSHIP]: {
    image: ImageDataType;
    name: string;
    bio: string;
    role: string;
  };
  [ModalNames.VIDEO]: {
    url: string;
  };
};

export type OpenModalPayload = { modalName: ModalNames; props: ModalTypes[ModalNames] };

export type Action =
  | { type: ACTIONS.setModalState; payload: OpenModalPayload }
  | { type: ACTIONS.setPortalNode; payload: HTMLDivElement }
  | { type: ACTIONS.clearModal };
