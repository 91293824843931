import React, { useEffect, useRef, Dispatch } from 'react';
import { setPortalNode } from './actions';
import { ModalReducer } from './reducer';
import { State, Action } from './types';

export const INITIAL_STATE: State = {
  modalName: undefined,
};

export const ModalContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export default function ModalProvider({ children }: React.PropsWithChildren<unknown>) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = React.useReducer(ModalReducer, INITIAL_STATE);

  useEffect(() => {
    dispatch(setPortalNode(modalRef.current!));
  }, []);

  return (
    <>
      <ModalContext.Provider value={{ state, dispatch }}>{children}</ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
}
