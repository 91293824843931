import React, { Dispatch } from 'react';
import { LanguageReducer } from './reducer';
import { State, Action } from './types';

export const INITIAL_STATE: State = {
  language: 'en',
};

export const LanguageContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export default function LanguageProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = React.useReducer(LanguageReducer, INITIAL_STATE);
  return <LanguageContext.Provider value={{ state, dispatch }}>{children}</LanguageContext.Provider>;
}
