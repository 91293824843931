import React, { useEffect } from 'react';
import ModalProvider from '@/base/context/Modal';
import ConfigProvider from '@/base/context/Config';
import { readQueryParams } from '@/base/helpers/queryParams';
import { formatParamsForUTM, queryParamsIncludesUTMValues, UTM_COOKIE_NAME } from '@/base/helpers/utm';
import { getCookie, setCookie } from '@/base/helpers/cookies';
import LanguageProvider from '@/base/context/Language';
import FeaturedEntriesProvider from '@/base/context/FeaturedEntries';
import combineComponents from '@/base/utils/combineComponents';

const UTM = ({ children }) => {
  useEffect(() => {
    const queryParams = readQueryParams() || {};
    const queryParamsIncludesUTM = queryParamsIncludesUTMValues(queryParams);
    const utmCookie = getCookie(UTM_COOKIE_NAME);

    if (
      (queryParamsIncludesUTM && JSON.stringify(formatParamsForUTM(queryParams)) !== utmCookie) ||
      (queryParamsIncludesUTM && !utmCookie)
    )
      setCookie(UTM_COOKIE_NAME, formatParamsForUTM(queryParams), { expires: 1 });
  }, []);

  return children;
};

const MainLayoutProvider = combineComponents(
  ConfigProvider,
  FeaturedEntriesProvider,
  LanguageProvider,
  ModalProvider,
  UTM,
);

export const wrapRootElement = ({ element }) => <MainLayoutProvider>{element}</MainLayoutProvider>;

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];
  window.locations.push(window.location.href);
  window.previousPath = window.locations[window.locations.length - 2];
};
