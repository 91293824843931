import Cookies, { CookieAttributes } from 'js-cookie';

export function getCookie(name?: string) {
  return name ? Cookies.get(name) : '';
}

// export function getCookie(name?: string) {
//   const cookie = name ? Cookies.get(name) : Cookies.get();

//   if (typeof cookie === 'string' && isJsonString(cookie)) return JSON.parse(cookie);

//   setCookie(name as string, '');
//   return {};
// }

export function setCookie(name: string, value: string | Record<string, unknown>, attributes?: CookieAttributes) {
  if (typeof value !== 'string') return Cookies.set(name, JSON.stringify(value), attributes);
  return Cookies.set(name, value, attributes);
}

export function updateCookie(name: string, value: string | Record<string, unknown>, attributes?: CookieAttributes) {
  const oldCookie = getCookie(name) || {};

  if (typeof value !== 'string' && typeof oldCookie !== 'string')
    return Cookies.set(name, JSON.stringify({ ...(oldCookie || {}), ...value }), attributes);

  if (typeof value === 'string' && typeof oldCookie === 'string')
    return Cookies.set(name, oldCookie + value, attributes);
}

export function deleteCookie(name: string) {
  Cookies.remove(name);
}
