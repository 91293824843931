import { State, Action, ACTIONS } from './types';

export const FeaturedEntriesReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.setEntries: {
      return {
        featuredEntries: action.payload.reduce((prevValue, currentValue, index) => {
          return prevValue + `${currentValue}${index !== action.payload.length - 1 ? ',' : ''}`;
        }, ''),
      };
    }
    default: {
      console.error(`Unhandled action type: ${(action as Action).type}`);
      return { ...state };
    }
  }
};
