enum UTM_QUERY_PARAMS {
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CONTENT = 'utm_content',
  UTM_TERM = 'utm_term',
}

export const UTM_COOKIE_NAME = 'dpx_utms';

export function formatParamsForUTM(params: { [key: string]: string }) {
  return {
    campaign: params[UTM_QUERY_PARAMS.UTM_CAMPAIGN],
    content: params[UTM_QUERY_PARAMS.UTM_CONTENT],
    medium: params[UTM_QUERY_PARAMS.UTM_MEDIUM],
    source: params[UTM_QUERY_PARAMS.UTM_SOURCE],
    term: params[UTM_QUERY_PARAMS.UTM_TERM],
  };
}

export function queryParamsIncludesUTMValues(source: { [key: string]: string }) {
  return !![...Object.values(UTM_QUERY_PARAMS)].find(utmParam => !!source[utmParam]);
}
