import React, { ComponentProps, FC } from 'react';

export default function combineComponents(...components: FC[]): FC {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }: ComponentProps<FC>): JSX.Element =>
        (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        ),
    ({ children }) => <>{children}</>,
  );
}
